import React from "react"

export default function Section({
  children,
  bgColor,
  secStyle,
  contentSpacing
  
}) {
  return (
    <div
      className={secStyle}
      style={{ background: `${bgColor}`, }}
    >
        <div className={contentSpacing}>
          {children}
        </div>
    </div>
  )
}

Section.defaultProps = {
  secStyle: "sec-lg",

  bgColor: { background: "transparent" },
}
